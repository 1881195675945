var arrPrice = [];
var arrHours = [];
var searchKeyword = '';

function serachPackage() {
    var val = $("#mySearch").val();

    console.log('serachPackage val --->', val);
    if (jQuery.type(val) === "string") {
        searchKeyword = '';
        if (val !== '' && val.length >= 3) {
            searchKeyword = val.trim().toLowerCase();
        }
        $("#pacakageList").empty();
        $("#pacakageList").append("<div class='loader'><img src=\img/DestinyLoader.gif></div>");
        $("#packageSearchDisp").empty();
        $("#packageSearchDisp").append(`
        <li id="all">
            <a href="javascript:void(0);" onClick="showPackages('all')" class="lang" key="ALL">All</a>
        </li>
        `);

        packageList();
    }
}

function packageList() {
    $.ajax({
        url: generateAPIURL() + "/package",
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({
            slug: mainObj.defaultPartner,
            type: "getPackages",
            keyword: searchKeyword
        }),
        success: function (packages) {
            $("#pacakageList").empty();
            ajaxResult = packages.data;
            localStorage.setItem('ajaxResult', JSON.stringify(ajaxResult));
            if (ajaxResult) {
                packageListArr = ajaxResult;
            }
            packageListSuccess(ajaxResult);
            $('#showFilterButton').show();
            getCartItemCount();
        },
        error: function (packagesError) {
            console.log('packagesError ==> Err ==> ', packagesError);
            if (packagesError.status === 500 && packagesError.responseJSON.error_type == "timeout") {
                webSocket(packagesError.responseJSON.data).then((response) => {
                    if (response && response.result > 0) {
                        $("#pacakageList").empty();
                        ajaxResult = response.result;
                        if (ajaxResult) {
                            packageListArr = ajaxResult;
                        }
                        packageListSuccess(packageListArr);
                    }
                }, (err) => {
                    toastr.error("Please reload your page...");
                    $("#sidePanelLoader").css('display', 'none');
                    console.log("package webSocket err ===>", err);
                });
            }
        }
    });
}

function packageListSuccess(products) {
    $("#pacakageList").empty();
    if (ajaxResult.length == 0) {
        $("#pacakageList").append(`<div class="col-lg-12">
   <div class="section-title-txt text-center">
     <p class="title-desc">
       No packages has been found
     </p>
   </div>
 </div>`);
    } else {
        for (var loop = 0; loop < ajaxResult.length; loop++) {
            for (p = 0; p <= ajaxResult[loop].Child.length; p++) {
                if (ajaxResult[loop].Child[p] && ajaxResult[loop].Child[p].id !== undefined) {
                    var total_price = 0;
                    var total_hours = 0;
                    var total_quty = 0;
                    var str_hours = '';
                    for (a = 0; a <= ajaxResult[loop].Child[p].preAssetList.length; a++) {
                        if (ajaxResult[loop].Child[p].preAssetList[a] && ajaxResult[loop].Child[p].preAssetList[a].id !== undefined) {
                            if (ajaxResult[loop].Child[p].preAssetList[a] && ajaxResult[loop].Child[p].preAssetList[a].public_price !== undefined) {
                                var price = ajaxResult[loop].Child[p].preAssetList[a].public_price;
                            }
                            if (ajaxResult[loop].Child[p].preAssetList[a] && ajaxResult[loop].Child[p].preAssetList[a].quantity !== undefined) {
                                var quantity = ajaxResult[loop].Child[p].preAssetList[a].quantity;
                            }
                            total_price = total_price + price * quantity;
                            total_quty = total_quty + quantity;
                            if (ajaxResult[loop].Child[p].preAssetList[a] && ajaxResult[loop].Child[p].preAssetList[a].hours !== undefined) {
                                total_hours = total_hours + ajaxResult[loop].Child[p].preAssetList[a].hours;
                            }
                        }
                    }
                    tPrice[ajaxResult[loop].Child[p].id] = total_price;
                    tHours[ajaxResult[loop].Child[p].id] = total_hours;
                    tQuantity[ajaxResult[loop].Child[p].id] = total_quty;
                    childName[ajaxResult[loop].Child[p].id] = ajaxResult[loop].Child[p].name;
                    childDescription[ajaxResult[loop].Child[p].id] = ajaxResult[loop].Child[p].description;
                }
            }
        }
        $.each(products, function (index, product) {
            productAddRow(product);
        });
    }
}

function productAddRow(product) {
    packageSearchList = `
 <li id="cls_${product.id}">
 <a href="javascript:void(0);" onClick="showPackages(${product.id})" >${product.name}</a>
 </li>
 `;
    var endDetails = "";
    var childList = "";
    var list = "";
    list = `
   <div class="row">
   <div class="boxes-title">
     <div class="row">
       <div class="col-lg-12">
         <div class="section-title-txt">              
          <div class="section-head">
            <h3 class="title notranslate" >
              ${product.name}
            </h3>
            <div class="right-side-price">
            <font class="lang" class="STARTING_FROM" key="STARTING_FROM">Starting from</font> <span>` + currancyFormat(product.startPrice) + `</span>USD
            </div>
          </div>
          <p class="section-title-desc">${product.description}</p>
          </div>
       </div>
     </div>
   </div>
 </div>

 <div class="row">
 <div class="all-boxes-view">
 <div class="row">`;

    threeList = `
 <div class="row">
 <div class="boxes-title">
   <div class="row">
      <div class="col-lg-12">
        <div class="section-title-txt">
          <div class="section-head ">
            <h3 class="title notranslate">
              ${product.name}
            </h3>
            <div class="right-side-price">
            <font class="lang" class="STARTING_FROM" key="STARTING_FROM">Starting from</font> <span>` + currancyFormat(product.startPrice) + `</span>USD
            </div>
          </div>
          <p class="section-title-desc">${product.description}</p>
          </div>
       </div>
     </div>
   </div>
 </div>
</div>
<div class="row">
 <div class="all-boxes-view custom-padding">
   <div class="row">
     <div class="col-lg-8 col-sm-12">
       <div class="row">`;

    $("#packageSearchDisp").append(packageSearchList);

    if (product.Child.length === 1) {
        childList = productOneChildRow(product.Child);
        endDetails = endProductList(product);
        $("#pacakageList").append('<div id=myID_' + product.id + '>' + list + childList + endDetails + '</div>');
    } else if (product.Child.length === 2) {
        childList = productTwoChildRow(product.Child);
        endDetails = endProductList(product);
        $("#pacakageList").append('<div id=myID_' + product.id + '>' + list + childList + endDetails + '</div>');
    } else if (product.Child.length === 3) {
        var threeDiv = '';
        childList = productThreeChildRow(product.Child);
        endDetails = endThreeProductList(product);
        threeDiv = threeList + childList + endDetails;
        $("#pacakageList").append('<div id=myID_' + product.id + '><div>' + threeDiv + '</div></div>');
    } else {
        childList = productChildAddRow(product.Child);
        endDetails = endProductList(product);
        $("#pacakageList").append('<div id=myID_' + product.id + '>' + list + childList + endDetails + '</div>');
    }

}

function productOneChildRow(child) {
    var childprod = "";
    var str_hours = "";

    for (p = 0; p <= child.length; p++) {
        if (child[p] && child[p].id !== undefined) {
            total_price = tPrice[child[p].id];
            total_hours = tHours[child[p].id];
            total_quantity = tQuantity[child[p].id];
        }
        if (total_hours > 0) {
            str_hours = "/" + total_hours;
        } else {
            str_hours = 0;
        }

        if (child[p] && child[p].name !== undefined) {
            childprod =
                childprod +
                `
               <div class="col-lg-8 col-sm-12">
								<div class="row">
									<div class="col-lg-12 custom-padd">
										<div class="box-list one-column d-flex flex-column light-color">
											<div class="assets-text border-0">
												<div class="assets-name notranslate">
                          ${child[p].name}
												</div>                                                
											</div>
                      <div class="center-img">
                        <img src="${child[p].image_url}" class="img-fluid" />
                      </div>
											<div class="assets-desc mt-auto">
                        <div class="d-flex flex-column">
                          <div class="price">
                          
                            <p><font class="lang" class="ESTIMATED_TIME_AND_COST" key="ESTIMATED_TIME_AND_COST">Estimated Time & Cost</font></p>
                            <div>
                              <span class="value">` + currancyFormat(total_price) + ` </span><span>USD / ` + hoursFormat(total_hours) + `</span>
                            </div>															
                          </div>
                          <div class="total-qty">
                            <p><font class="lang" class="TOTAL_QUANTITY" key="TOTAL_QUANTITY">Total Quantity</font> ${total_quantity}</p>                            															
                          </div>
                        </div>														
                        <div class="assets-btn d-flex justify-content-between align-items-center">
                            <a href="javascript:void(0)" class="view-detail-btn" onclick="viewDetails(${child[p].parent_crm_lead_type},${child[p].id})">
                              <font class="lang" class="VIEW_DETAILS" key="VIEW_DETAILS">View Details</font>                            
                            </a>
                            <div>                            
                                <a href="javascript:void(0)" class="btn-buy btn-cart mr-1" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id})">
                                    <font class="lang" class="BUY_BUTTON" key="ADD_TO_CART">Add <span class="cart-icon"></span></font>
                                </a>
                                <a href="javascript:void(0)" class="btn-buy" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id}, true)">
                                    <font class="lang" class="BUY_BUTTON" key="BUY_BUTTON">Buy</font>
                                </a>
                            </div>
                        </div>
											</div>
										</div>
									</div>
								</div>
							</div>
   `;
        }
    }

    return childprod;
}

function productTwoChildRow(child) {
    var childprod = "";
    for (p = 0; p <= child.length; p++) {
        if (child[p] && child[p].id !== undefined) {
            var total_price = 0;
            var total_hours = 0;
            var str_hours = "";
            total_price = tPrice[child[p].id];
            total_hours = tHours[child[p].id];
            total_quantity = tQuantity[child[p].id];
        }
        if (total_hours > 0) {
            str_hours = "/ " + total_hours;
        } else {
            str_hours = 0;
        }

        if (child[p] && child[p].name !== undefined) {
            childprod =
                childprod +
                `
        <div class="col-sm-12 col-md-6 col-lg-4 col-12">
          <div class="box-list two-column d-flex flex-column">
            <div class="assets-text border-0">
              <div class="assets-name notranslate">
                ${child[p].name}
              </div>                            
            </div>            
            <div class="center-img"> 
              <img src="${child[p].image_url}" class="img-fluid">
            </div>
            <div class="assets-desc">
              <div class="d-flex flex-column mb-3">
                <div class="price">
                  <p><font class="lang" class="ESTIMATED_TIME_AND_COST" key="ESTIMATED_TIME_AND_COST">Estimated Time & Cost</font></p>
                  <div>
                    <span class="value">` + currancyFormat(total_price) + `</span><span>USD / ` + hoursFormat(total_hours) + `</span>
                  </div>															
                </div>
                <div class="total-qty">
                  <p><font class="lang" class="TOTAL_QUANTITY" key="TOTAL_QUANTITY">Total Quantity</font> ${total_quantity}</p>               														
                </div>
              </div>  
              <div class="assets-btn d-flex justify-content-between align-items-center">
                <a href="javascript:void(0)" class="view-detail-btn" onclick="viewDetails(${child[p].parent_crm_lead_type},${child[p].id})">
                <font class="lang" class="VIEW_DETAILS" key="VIEW_DETAILS">View Details</font>
                </a>
                <div>                
                    <a href="javascript:void(0)" class="btn-buy btn-cart mr-1" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id})">
                    <font class="lang" class="BUY_BUTTON" key="ADD_TO_CART">Add <span class="cart-icon"></span></font>
                    </a>
                    <a href="javascript:void(0)" class="btn-buy" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id}, true)">
                    <font class="lang" class="BUY_BUTTON" key="BUY_BUTTON">Buy</font>
                    </a>
                </div>
              </div>           
            </div>
         </div>
       </div>
   `;
        }
    }

    return childprod;
}

function productThreeChildRow(child) {
    var childprod = "";
    for (p = 0; p <= child.length; p++) {
        if (child[p] && child[p].id !== undefined) {
            var total_price = 0;
            var total_hours = 0;
            var str_hours = "";
            total_price = tPrice[child[p].id];
            total_hours = tHours[child[p].id];
            total_quantity = tQuantity[child[p].id];
        }
        if (total_hours > 0) {
            str_hours = "/ " + total_hours;
        } else {
            str_hours = 0;
        }

        var cls = "col-md-6 col-sm-12 col-lg-6";
        var fcls = "box-list small-box";
        if (p === 1) {
            cls = "col-md-6 col-sm-12 col-lg-6 custom-padd";
            fcls = "box-list small-box";
        }
        if (p === 2) {
            cls = "col-lg-12 col-sm-12 custom-padd";
            fcls = "box-list small-box margin-top-space";

        }

        if (child[p] && child[p].name !== undefined) {
            childprod =
                childprod +
                `
        <div class="${cls}"> 
			<div class="${fcls}">
				<div class="assets-text">
					<div class="assets-name notranslate">
                       ${child[p].name}
					</div>
                    <div class="right-emal-icon">
                         <img src="${child[p].image_url}" class="img-fluid">
                    </div>                 
				</div>
				<div class="assets-desc">
                    <div class="d-flex flex-column">
						<div class="price">
                            <p><font class="lang" class="ESTIMATED_TIME_AND_COST" key="ESTIMATED_TIME_AND_COST">Estimated Time & Cost</font></p>
                            <div>
                                <span class="value">` + currancyFormat(total_price) + `</span><span>USD / ` + hoursFormat(total_hours) + `</span>
                            </div>															
                        </div>
                        <div class="total-qty">
                            <p><font class="lang" class="TOTAL_QUANTITY" key="TOTAL_QUANTITY">Total Quantity</font> ${total_quantity}</p>                            
                        </div>
                    </div>                    
                    <div class="assets-btn d-flex justify-content-between align-items-center">
                        <a href="javascript:void(0)" class="view-detail-btn" onclick="viewDetails(${child[p].parent_crm_lead_type},${child[p].id})">
                        <font class="lang" class="VIEW_DETAILS" key="VIEW_DETAILS">View Details</font>
                        </a>
                        <div>
                            <a href="javascript:void(0)" class="btn-buy btn-cart mr-1" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id})">
                            <font class="lang" class="BUY_BUTTON" key="ADD_TO_CART">Add <span class="cart-icon"></span></font>
                            </a>
                            <a href="javascript:void(0)" class="btn-buy" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id}, true)">
                            <font class="lang" class="BUY_BUTTON" key="BUY_BUTTON">Buy</font>
                            </a>
                        </div>
					</div>
				</div>
			</div>
       </div>
                 
   `;
        }
    }

    return childprod;
}

function productChildAddRow(child) {
    var childprod = "";
    for (p = 0; p <= child.length; p++) {
        if (child[p] && child[p].id !== undefined) {
            var total_price = 0;
            var total_hours = 0;
            var str_hours = "";
            total_price = tPrice[child[p].id];
            total_hours = tHours[child[p].id];
            total_quantity = tQuantity[child[p].id];
        }
        if (total_hours > 0) {
            str_hours = "/ " + total_hours;
        } else {
            str_hours = 0;
        }
        if (p <= 1) {
            if (child[p] && child[p].name !== undefined) {
                childprod =
                    childprod +
                    `
        <div class="col-lg-4 col-sm-4 col-12">
            <div class="box-list two-column d-flex flex-column">
                <div class="assets-text border-0">
                    <div class="assets-name notranslate">
                        ${child[p].name}
                    </div>   
                </div>           
                <div class="center-img"> 
                <img src="${child[p].image_url}" class="img-fluid">
                </div> 
                <div class="assets-desc mt-auto">
                    <div class="d-flex flex-column mb-3">
                        <div class="price">
                            <p><font class="lang" class="ESTIMATED_TIME_AND_COST" key="ESTIMATED_TIME_AND_COST">Estimated Time & Cost</font></p>
                            <div>
                                <span class="value">` + currancyFormat(total_price) + `</span><span>USD / ` + hoursFormat(total_hours) + `</span>
                            </div>															
                        </div>
                        <div class="total-qty">
                            <p><font class="lang" class="TOTAL_QUANTITY" key="TOTAL_QUANTITY">Total Quantity</font>${total_quantity}</p>                            															
                        </div>
                    </div>
                    <div class="assets-btn d-flex justify-content-between align-items-center">
                        <a href="javascript:void(0)" class="view-detail-btn" onclick="viewDetails(${child[p].parent_crm_lead_type},${child[p].id})">
                        <font class="lang" class="VIEW_DETAILS" key="VIEW_DETAILS">View Details</font>
                        </a>
                        <div>
                            <a href="javascript:void(0)" class="btn-buy btn-cart mr-1" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id})">
                            <font class="lang" class="BUY_BUTTON" key="ADD_TO_CART">Add <span class="cart-icon"></span></font>
                            </a>
                            <a href="javascript:void(0)" class="btn-buy" onclick="addToCart(${child[p].parent_crm_lead_type},${child[p].id}, true)">
                            <font class="lang" class="BUY_BUTTON" key="BUY_BUTTON">Buy</font>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   `;
            }
        }
    }

    return childprod;
}

function endProductList(product) {
    var endProd = "";
    endProd = `
   <div class="col-lg-4 col-sm-12 col-12">
   <div class="box-list view-all">
     <div class="all-title">
     <font class="lang" id="BUY_ALL" key="BUY_ALL">Buy All</font> <br>
     <span>${product.name}</span>  <font class="lang" id="OPTIONS" key="OPTIONS">options</font>
     </div>
     <div class="all-view-btn">
       <a href="javascript:void(0)" class="btn btn-view" onclick="openSidebar(${product.id})">
         View all
       </a>
     </div>
   </div>
   </div>
   </div>
   </div>
   </div>
   `;
    return endProd;
}

function endThreeProductList(product) {
    var endProd = "";
    endProd = `
 </div>
 </div>
 <div class="col-lg-4 col-sm-12">
   <div class="box-list view-all">
     <div class="all-title">
     <font class="lang" id="BUY_ALL" key="BUY_ALL">Buy All</font> <br>
       <span>${product.name}</span>  <font class="lang" id="OPTIONS" key="OPTIONS">options</font>
     </div>
     <div class="all-view-btn">
     <a href="javascript:void(0)" class="btn btn-view" onclick="openSidebar(${product.id})">         
         <font class="lang" class="VIEW_ALL" key="VIEW_ALL">View all</font>         
       </a>
     </div>
   </div>
 </div>
</div>
</div>
</div>
   `;
    return endProd;
}

function packageallChildList(ID) {
    $("#pacakageHead").append("<img src=\img/DestinyLoader.gif>");
    $("#pacakageHead").empty();
    $("#pkg-desc").empty();
    packageallchildListSuccess(ajaxResult, ID);
}

function packageallchildListSuccess(ChildProducts, ID) {
    // $.each(ChildProducts, function (index, ChildProduct) {
    for (var loop = 0; loop < ChildProducts.length; loop++) {
        var list = "";
        var clist = "";
        var allList = [];
        var packageTitle = "";
        var packageDesc = "";
        if (ChildProducts[loop].id === ID) {
            packageTitle = `
     <h2>${ChildProducts[loop].name}</h2>
     <p></p>
     `;
            packageDesc = ChildProducts[loop].description;

            $("#pacakageHead").append(packageTitle);
            $("#pkg-desc").append(packageDesc);

            for (var c = 0; c < ChildProducts[loop].Child.length; c++) {
                if (
                    ChildProducts[loop].Child[c] &&
                    ChildProducts[loop].Child[c].id !== undefined
                ) {
                    total_price = tPrice[ChildProducts[loop].Child[c].id];
                    total_hours = tHours[ChildProducts[loop].Child[c].id];
                    total_quantity = tQuantity[ChildProducts[loop].Child[c].id];
                }

                if (total_hours > 0) {
                    str_hours = "/ " + total_hours;
                }

                list =
                    list +
                    `
        <li>
            <div class="box-list d-flex flex-column">
                <div class="assets-text border-0">
                  <div class="assets-name notranslate">${ChildProducts[loop].Child[c].name}</div>                    
                </div>   
                <div class="center-img">
                  <img src="${ChildProducts[loop].Child[c].image_url}" class="img-fluid" />
                </div>
                <div class="assets-desc mt-auto">
                    <div class="d-flex flex-column">
                        <div class="price">
                            <p>Estimated Time & Cost</p>
                            <div>
                                <span class="value">` + currancyFormat(total_price) + `</span><span>USD / ` + hoursFormat(total_hours) + `</span>
                            </div>															
                        </div>
                        <div class="total-qty">
                            <p>Total Quantity ${total_quantity}</p>                            															
                        </div>
                    </div>
                    <div class="assets-btn d-flex justify-content-between align-items-center">
                        <a href="javascript:void(0)" class="view-detail-btn" onclick="viewDetails(${ChildProducts[loop].id},${ChildProducts[loop].Child[c].id})">
                            View Details
                        </a>
                        <div>
                            <a href="javascript:void(0)" class="btn-buy btn-cart mr-1" onclick="addToCart(${ChildProducts[loop].id},${ChildProducts[loop].Child[c].id})">
                            Add <span class="cart-icon"></span>
                            </a>
                            <a href="javascript:void(0)" class="btn-buy" onclick="addToCart(${ChildProducts[loop].id},${ChildProducts[loop].Child[c].id}, true)">
                                Buy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>`;
            }
        }
        $("#pacakageAllChildList").append(list);
    }
    // });
}

function assetList(parentID, childID) {
    $("#assetDetails").append("<img src=\img/DestinyLoader.gif>");
    $("#assetDetails").empty();
    $("#totalDetails").empty();
    assetListSuccess(parentID, childID, ajaxResult);
}

function assetListSuccess(parentID, childID, products) {
    $("#assetDetailsDesc").empty();
    $("#assetDetailsTitle").empty();
    for (var lop = 0; lop < products.length; lop++) {
        if (products[lop].id === parentID) {

            for (var loop = 0; loop < products[lop].Child.length; loop++) {
                if (products[lop].Child[loop].id === childID) {
                    console.log(loop)
                    $("#assetDetailsDesc").append(products[lop].Child[loop].description)
                    $("#assetDetailsTitle").append(products[lop].Child[loop].name)
                    var displayAssetDetails = displayAsset(products[lop].Child[loop]);
                    $("#assetDetails").append(displayAssetDetails);
                }
            }
        }
    }

    $("#totalDetails").append(`
 <div class="footer-left">
         <div class="total-value d-flex">
         Total:<span>` + hoursFormat(tHours[childID]) + `</span> / <span>` + currancyFormat(tPrice[childID]) + `</span><span class="notranslate">USD</span>
         </div>
					<a href="javascript:void(0);" class="text-link">View Terms & Conditions</a>
				</div>				
				<a href="javascript:openSidebar1(` + parentID + `,` + childID + `);" class="btn btn-skyblue">Buy</a>`);
}

function displayAsset(ChildAsset) {
    var assetList = "";
    var price = 0;
    var hours = 0;

    for (var loop1 = 0; loop1 < ChildAsset.preAssetList.length; loop1++) {
        var str_hours = "";

        price = ChildAsset.preAssetList[loop1].public_price * ChildAsset.preAssetList[loop1].quantity;
        hours = ChildAsset.preAssetList[loop1].hours;

        if (hours > 0) {
            str_hours = hours;
        } else {
            str_hours = 0;
        }




        assetList =
            assetList +
            `
    
     <tr>
         <td>
         ${ChildAsset.preAssetList[loop1].product_name}
         </td>
         <td>
         ${ChildAsset.preAssetList[loop1].quantity}
         </td>
         <td>
         ` + hoursFormat(str_hours) + `
         </td>
         <td>
         <span> ` + currancyFormat(ChildAsset.preAssetList[loop1].public_price * ChildAsset.preAssetList[loop1].quantity) + ` </span>
         </td>
         </tr>
  
       `;
    }
    return assetList;
}


function stripeResponseHandler(status, response) {

    // Grab the form:
    var $form = $('#cardData');

    if (response.error) { // Problem!

        // Show the errors on the form
        $form.find('.payment-errors').text(response.error.message);
        $form.find('button').prop('disabled', false); // Re-enable submission

    } else { // Token was created!

        // Get the token ID:
        var token = response.id;

        // Insert the token into the form so it gets submitted to the server:
        $form.append($('<input type="hidden" name="stripeToken" />').val(token));

        // Submit the form:
        $form.get(0).submit();

    }
}

function addncheckOut() {


    var exp_date = $('#cardDate').val().split('/');

    $('#cardNameerrMsg > span').hide();
    $('#cardNumerrMsg > span').hide();
    $('#cardDateerrMsg > span').hide();
    $('#cvverrMsg > span').hide();

    // var validated = false;
    // var msg = 'Please provide required information.';
    // if (!$('#cardName').val()) {
    //     msg1 = 'Please provide card name';
    //     $('#cardNameerrMsg > span').html(msg1);
    // } else if (!$('#cardNumber').val()) {
    //     msg2 = 'Please provide your card number';
    //     $('#cardNumerrMsg > span').html(msg2);
    // } else if (!$('#cardDate').val()) {
    //     msg3 = 'Please provide MM YYYY';
    //     $('#cardDateerrMsg > span').html(msg3);
    // } else if (!$('#cardCVV').val()) {
    //    msg4 = 'Please provide cardCVV';
    //     $('#cvverrMsg > span').html(msg4);
    // } else if(!Stripe.card.validateCardNumber($('#cardNumber').val())){
    //   msg2 = 'Please provide valid card number';
    //   $('#cardNumerrMsg > span').html(msg2);
    // } else if(!Stripe.card.validateExpiry($('#cardDate').val())){
    //   msg3 = 'Please provide valid expiration';
    //   $('#cardDateerrMsg > span').html(msg3);
    // } else if(!Stripe.card.validateCVC($('#cardCVV').val())){
    //   msg4 = 'Please provide valid cvv';
    //   $('#cvverrMsg > span').html(msg4);
    // } else {
    //     validated = true;
    // }

    if (checkValid()) {
        return false;

    } else {

        $('#cardNameerrMsg > span').hide();
        $('#cardNumerrMsg > span').hide();
        $('#cardDateerrMsg > span').hide();
        $('#cvverrMsg > span').hide();

        if (checkuserlogin()) {
            console.log("user logged in...");
            $("#sidePanelLoader").css('display', 'flex');
            var param = {
                "type": "AddCard",
                "user_id": getCookie('uid'),
                "slug": mainObj.defaultPartner,
                "brand": {
                    "id": getCookie('bid'),
                    "partner_id": getCookie('cid')
                },
                "card": {
                    "first_name": $('#cardName').val(),
                    "last_name": $('#cardName').val(),
                    "number": $('#cardNumber').val(),
                    "exp_month": exp_date[0],
                    "exp_year": exp_date[1],
                    "cvc": $('#cardCVV').val()
                }
            }



            jQuery.ajax({
                url: generateAPIURL() + '/card',
                dataType: 'json',
                data: JSON.stringify(param),
                type: 'POST',
                headers: generateHeader(),
                success: function (userResponse) {

                    if (userResponse.data.result.stripe_card_id !== '') {
                        var postpay = {
                            "type": "PostPayOnStripe",
                            "user_id": getCookie('uid'),
                            "slug": mainObj.defaultPartner,
                            "saleOrderID": userInfo.campaign.soid,
                            "payment": {
                                "amount": userInfo.total_price,
                                "description": "Payment from commarcial flow",
                                "stripe_card_id": userResponse.data.result.stripe_card_id
                            }
                        }

                        jQuery.ajax({
                            url: generateAPIURL() + '/pay',
                            dataType: 'json',
                            data: JSON.stringify(postpay),
                            type: 'POST',
                            headers: generateHeader(),
                            success: function (response) {
                                $("#sidePanelLoader").css('display', 'none');
                                $("#paymentData").css('display', 'none');
                                $("#thankyou").css('display', 'block');
                                removeuserCookieData();
                                removeCartData();
                                var brefer = mainObj.defaultBrandreference; //userInfo.brand_referance
                                var camID = userInfo.campaign.campaignId
                                var qNum = userInfo.campaign.quotation_number;
                                var prodTempId = userInfo.campaign.product_template_firstid;
                                var url = generateTrackURL() + brefer + '/' + camID + '/quotationId/' + qNum + '/module-list/' + prodTempId;

                                // $("#linkurl").append("You will be redirected to <a href="+trackurl+"><b>W</b>-Track</a> to continue with your Quotation");
                                $("#linkurl").append("You will be redirected to <a href='javascript:void(0);' onclick='javascript:redirectUser(\"" + url + "\", \"webpage\")'><b>W</b>-Track</a> to continue with your Quotation");

                                redirectUser(url, 'webpage');
                                userInfo.brand_referance = mainObj.defaultBrandreference;
                                userInfo.campaign.campaignId = 0;
                                userInfo.campaign.quotation_number = 0;
                                userInfo.campaign.product_template_firstid = 0;
                                // setTimeout(function () {
                                //   userInfo.brand_referance = '';
                                //   userInfo.campaign.campaignId = 0;
                                //   userInfo.campaign.quotation_number = 0;
                                //   userInfo.campaign.product_template_firstid = 0;
                                //   $("#linkurl").hide();
                                //     // window.location=trackurl;
                                //     closeSidebar1();
                                //     $("#thankyou").css('display','none');
                                //     redirectUser(url,'webpage');
                                //  }, 7000);
                            },
                            error: function (userError) {
                                console.log('userError ==> ', userError);
                                $("#sidePanelLoader").css('display', 'none');
                                toastr.error(userError.responseJSON.message);
                            }
                        });

                    }
                },
                error: function (userError) {
                    console.log('userError ==> ', userError);
                    $("#sidePanelLoader").css('display', 'none');
                    toastr.error(userError.responseJSON.message);
                }
            });
        }
    }
}

function checkValid(check = "all") {

    var CName = $('#cardName').val()
    var CNum = $('#cardNumber').val()
    var CDate = $('#cardDate').val()
    var CCvv = $('#cardCVV').val()
    CNum = CNum.replace(/-/g, '');
    var validated = false;
    if (check === "all" || check === "cardName-require-validation") {
        if (!CName) {
            $("#cardName-require-validation").show();
            validated = true;
        } else {
            $("#cardName-require-validation").hide();
        }
    }
    if (check === "all" || check === "cardNumber-require-validation") {
        if (!CNum) {
            $("#cardNumber-require-validation").show();
            validated = true;
        } else {
            $("#cardNumber-require-validation").hide();
        }
    }
    if (check === "all" || check === "cardNumber-valid-validation") {
        if (CNum && !Stripe.card.validateCardNumber($('#cardNumber').val())) {
            $("#cardNumber-valid-validation").show();
            validated = true;
        } else {
            $("#cardNumber-valid-validation").hide();
        }
    }
    if (check === "all" || check === "cardDate-require-validation") {
        if (!CDate) {
            $("#cardDate-require-validation").show();
            validated = true;
        } else {
            $("#cardDate-require-validation").hide();
        }
    }
    if (check === "all" || check === "cardDate-valid-validation") {
        if (CNum && !Stripe.card.validateExpiry($('#cardDate').val())) {
            $("#cardDate-valid-validation").show();
            validated = true;
        } else {
            $("#cardDate-valid-validation").hide();
        }
    }
    if (check === "all" || check === "cardCVV-require-validation") {
        if (!CCvv) {
            $("#cardCVV-require-validation").show();
            validated = true;
        } else {
            $("#cardCVV-require-validation").hide();
        }
    }
    if (check === "all" || check === "cardCVV-valid-validation") {
        if (CCvv && !Stripe.card.validateCVC($('#cardCVV').val())) {
            $("#cardCVV-valid-validation").show();
            validated = true;
        } else {
            $("#cardCVV-valid-validation").hide();
        }
    }
    return validated;
}

function showPackages(id) {

    var $this = $(this),
        clickNum = $this.data('clickNum');
    clickCount = $this.data('clickCount');

    if (!clickNum) clickNum = 1;
    if (!clickCount) clickCount = 1;

    if (id === 'all') {
        $this.data('clickCount', 1);
        clickCount = 1;
    }

    if (id === 'all') {
        $("div[id*=myID_]").removeAttr('style');
        $("div[id*=myID_]").css('display', 'initial');
        $('#all').addClass('active');
        $("li[id*=cls_]").removeClass('active');
    } else {

        if (clickNum === 1 && id !== 'all') {
            $("div[id*=myID_]").css('display', 'none');
            $('#myID_' + id).css('display', 'block');
            $this.data('clickCount', ++clickCount);
            $('#cls_' + id).addClass('active');
            $('#all').removeClass('active');

        } else if ($('#myID_' + id).css('display') === 'inline') {

            $("div[id*=myID_]").css('display', 'none');
            $('#myID_' + id).css('display', 'block');
            $this.data('clickCount', ++clickCount);
            $('#cls_' + id).addClass('active');
            $('#all').removeClass('active');

        } else {

            if ($('#myID_' + id).css('display') === 'block') {
                $this.data('clickCount', --clickCount);
                $('#myID_' + id).css('display', 'none');
                $('#cls_' + id).removeClass('active');
            } else {
                $this.data('clickCount', ++clickCount);
                $('#myID_' + id).css('display', 'block');
                $('#cls_' + id).addClass('active');
            }

        }


        if (clickCount === 1) {
            clickCount = 1;
            $("div[id*=myID_]").removeAttr('style');
            $("div[id*=myID_]").css('display', 'initial');
            $('#all').addClass('active');
            $("li[id*=cls_]").removeClass('active');
        }

        $this.data('clickNum', ++clickNum);
    }

}